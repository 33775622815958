require('./bootstrap');



// // Show dropdown
// $('.selected').click(function () {
//     $('.custom-sel').addClass('show-sel');
//     $('.custom-sel a').removeClass('hidden');
// });
//
// // Hide dropdown when not focused
// $('.custom-sel').focusout(function () {
//     $('.custom-sel').removeClass('show-sel');
//     $('.custom-sel a:not(:first)').addClass('hidden');
// }).blur(function () {
//     $('.custom-sel').removeClass('show-sel');
//     $('.custom-sel a:not(:first)').addClass('hidden');
// });
window.onscroll = function() {stickyHeader()};

var header = document.getElementById("main-header");
var sticky = header.offsetTop;

function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}
$('.close-icon').on('click', function () {
    $('.notification').hide();
})